@import "styles/breakpoints.scss";
@import "styles/dimensions.scss";

$elementHeight: var(--header-element-height);

.Header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  top: 0;
  background-color: var(--secondary-color-dark);
  transform-style: preserve-3d;
  z-index: 5;
  @media (min-height: 500px) {
    position: sticky;
  }

  & > *:not(.Navigation) {
    height: $elementHeight;
  }

  & > *:not(:first-child):not(.Navigation):not(.SearchBar) {
    margin-left: var(--element-whitespace);
  }

  .mobile-menu-button {
    fill: #fff;
    width: $elementHeight;
    height: $elementHeight;
  }

  @media (min-width: $hd) {
    flex-wrap: wrap;

    & > *:not(.Navigation) {
      margin-top: var(--element-whitespace);
      margin-bottom: var(--element-whitespace);
    }

    .logo {
      margin: var(--element-whitespace);
      width: var(--header-element-height);
    }

    .Cart {
      margin-right: var(--element-whitespace);
    }

    .mobile-menu-button {
      display: none !important;
    }
  }

  @media (max-width: $hd) {
    padding: var(--element-whitespace);

    .logo {
      // TODO change this
      margin-right: auto;
    }

    &.search-active {
      & > *:not(.SearchBar) {
        position: absolute;
        top: -100vh;
      }
      .SearchBar {
        max-width: 100%;
        flex: 1;
        margin-left: auto;
        width: 100%;

        input {
          color: #000;
        }

        button {
          display: inline-block;
        }
      }
    }
  }

  &.search-active {
    .SearchBar {
      .search-icon {
        display: none;
      }
    }
  }

  .SearchBar {
    position: relative;
    transition: flex 0.2s;
    display: flex;
    z-index: 1;
    max-width: calc(580 / 16 * 1rem);

    .search-icon {
      position: absolute;
      text-align: center;
      z-index: 1;
      top: calc(var(--header-element-height) * 0.25);
      left: calc(var(--header-element-height) * 0.25);
      pointer-events: none;
      height: calc(var(--header-element-height) * 0.5);
      width: calc(var(--header-element-height) * 0.5);
    }

    form {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }

    .suggestion-slide-hide {
      position: absolute;
      width: 100%;
      height: calc(calc(20 / 16 * 1rem) + var(--element-whitespace));
      background-color: var(--secondary-color-dark);
      z-index: 1;
      top: calc(var(--element-whitespace) * -1);
      left: 0;
    }

    .search-suggestions {
      position: absolute;
      flex-direction: column;
      bottom: 0;
      left: 0;
      transform: translateY(-100%);
      width: 100%;
      background-color: var(--tertiary-color-light);
      display: none;

      &.active {
        display: flex;
        animation: show_suggestions 0.2s ease-in-out forwards;
      }

      button:first-child {
        margin-top: calc(20 / 16 * 1rem);
      }

      button {
        flex: 1;
        margin: 0;
        cursor: pointer;
        border: 0;
        border-radius: 0;
        background: none;
        padding: calc(3 / 16 * 1rem) 0 calc(3 / 16 * 1rem) calc(10 / 16 * 1rem);
        text-align: left;
      }
    }

    .search-bar-input {
      flex: 1;
      position: relative;
      transform-style: preserve-3d;
    }
    input {
      min-width: $elementHeight;
      border-radius: calc(var(--header-element-height) / 2);
      width: 100%;
      height: 100%;
      padding-left: var(--element-whitespace);
      padding-right: var(--element-whitespace);
      border: 0;
      transform: translateZ(10px);
    }

    button {
      border-radius: calc(var(--header-element-height) * 0.5);
      margin-left: var(--element-whitespace);
    }

    @media (min-width: $hd) {
      flex: 1;
      margin-left: auto;
      margin-right: auto;

      .search-icon {
        left: calc(var(--header-element-height) * 0.35);

        &.search-input-entered {
          display: none;
        }
      }
    }

    @media (max-width: $hd) {
      width: $elementHeight;

      input {
        color: #fff;
      }

      button {
        display: none;
      }
    }
  }

  .Cart {
    width: $elementHeight;
    height: $elementHeight;
    path {
      fill: #fff;
    }
  }

  .Navigation {
    ul {
      display: flex;
      padding: var(--element-whitespace);
      width: 100%;
      margin-block-start: 0;
      margin-block-end: 0;
      list-style: none;
      background-color: var(--main-color-light);

      li {
        position: relative;
        &.active-page {
          a {
            font-weight: bold;
            color: var(--secondary-color-dark);
          }
        }
        .link-underline {
          position: absolute;
          width: 0;
          height: 2px;
          pointer-events: none;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @media (min-width: $hd) {
      width: 100%;

      ul {
        li {
          margin: 0 var(--element-whitespace);
          &.active-page {
            .link-underline {
              animation: activate_link_underline 0.3s forwards;
            }
          }
        }
        li:last-child {
          margin-right: auto;
        }
        li:first-child {
          margin-left: auto;
        }
      }
    }

    @media (max-width: $hd) {
      transform: translateZ(-10px);
      transform-style: preserve-3d;
      width: calc(100% + var(--element-whitespace) * 2);
      &:focus-within {
        ul {
          transform: translateZ(9px)
            translateY(calc(100% + var(--element-whitespace)));
          pointer-events: all;
        }
      }

      ul {
        width: calc(100% + var(--element-whitespace) * 2);
        transform: translateZ(9px) translateY(0);
        flex-direction: column;
        transition: transform 0.3s;
        bottom: 0;
        left: calc(-1 * var(--element-whitespace));
        pointer-events: none;
        position: absolute;
        li {
          margin-left: auto;

          &.active-page {
            a::before {
              content: "> ";
            }
          }
        }
        li:not(:last-child) {
          margin-bottom: var(--element-whitespace);
        }
      }
    }
  }
}

@keyframes show_suggestions {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(calc(100% - calc(var(--header-element-height) / 2)));
  }
}

@keyframes activate_link_underline {
  0% {
    width: 0;
    background-color: var(--main-color-light);
  }
  100% {
    width: 100%;
    background-color: var(--secondary-color-dark);
  }
}

@import "styles/breakpoints.scss";
.Page{
    flex: 1;
    display: flex;
    flex-direction: column;
    @media (min-width: $hd) {
      padding: var(--element-whitespace);
    }
    &>*{
      max-width: calc(1000 / 16 * 1rem);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
}
.CartContainer{
  &.requesting{
    .cart-title{
      display: none;
    }
    .Form{
      display: none;
    }
  }
    h1{
        text-align: center;
    }
      .CartRow,
      .total-amount,
      .terms {
        border-radius: 0 !important;
      }
      .total-amount {
        text-align: right;
        max-width: 100%;
        padding: var(--element-whitespace) !important;
        margin-bottom: var(--body-padding);
      }
      .Modal{
          max-width: 100%;
      }
      .modal-container{
          //max-width: 1000px;
          width: 100%;
          height: 100%;
      }
      .TermsOfSale{
          width: calc(100% - var(--element-whitespace) * 2);
      }
    
      .terms{
          border: 0 !important;
          font-size: 1em !important;
          display: flex;
          &>*:first-child{
            margin-left: auto;
          }
          &>*:last-child{
            margin-right: auto;
          }
          .CheckBox{
              margin-top: var(--element-whitespace);
          }
      }
      .customer-email {
        &.Label {
          padding: 0 var(--element-whitespace);
        }
        &.Input {
          margin: 0 var(--element-whitespace);
          width: auto;
        }
      }
      .CartRow {
        border: 0 !important;
        padding: 0 !important;
        & > div {
          padding: var(--element-whitespace);
          &:not(:last-child) {
            border-bottom: 1px solid var(--secondary-color-dark);
          }
        }
        h3 {
          margin: 0;
        }
      }
      .video-info {
        background-color: var(--secondary-color-dark);
        * {
          color: #fff;
        }
      }
      .Cart {
        min-width: calc(320 / 16 * 1rem);
      }
      .Ticket {
        display: flex;
        flex-direction: row;
        border-left: 1px solid var(--secondary-color-dark);
        border-right: 1px solid var(--secondary-color-dark);
        .Button {
          margin: auto 0 auto var(--element-whitespace);
        }
        p {
          margin: 0;
        }
        .Input {
          max-width: calc(50 / 16 * 1rem);
        }
        .ticket-info {
          max-width: 50%;
          & > * {
            margin-bottom: calc(8 / 16 * 1rem);
          }
        }
        .ticket-pricing {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          & > * {
            margin-left: auto;
          }
          p {
            text-align: right;
            margin-top: var(--element-whitespace);
          }
        }
      }
}
.ContactPage {
  .container {
    display: flex;
    flex-direction: column;
    & > h2 {
      text-align: center;
    }
  }

  .Form {
    min-width: calc(320 / 16 * 1rem);
    width: 80%;
    margin: 0 auto var(--body-padding) auto;
  }

  .FAQ {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    &:not(:last-of-type) {
      border-bottom: 2px solid var(--tertiary-color-light);
    }
    &:last-of-type {
      margin-bottom: var(--body-padding);
    }

    h3 {
      text-align: left;
      margin-right: var(--element-whitespace);
    }
    .faq-container {
      transform: translateY(-102%);
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      transition: transform 0.2s ease-in-out;
      padding: var(--element-whitespace);
    }

    .dropdown-toggle {
      z-index: 1;
      border-radius: 0;
      
      span {
        display: flex;
        &::after {
          margin: auto 0 auto auto;
          content: " \25BC";
        }
      }
    }

    &:focus-within {
      .faq-container {
        transform: translateY(0);
        visibility: visible;
        pointer-events: all;
        position: static;
      }

      .dropdown-toggle span {
        &::after {
          content: " \25B2";
        }
      }
    }
  }
}

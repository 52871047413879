@import "styles/breakpoints.scss";

.Footer {
  background-color: var(--main-color-light);
  color: var(--secondary-color-dark);
  padding: var(--element-whitespace);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--element-whitespace);

  @media (min-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }

  ul {
    margin: 0;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: var(--element-whitespace);
    margin-left: auto;
    text-align: right;

    & > * {
      margin-top: 0;
    }
    & > *:not(:last-child) {
      margin-bottom: var(--element-whitespace);
    }
    ul {
      list-style: none;
      padding: 0;
    }

    .footer-column-border {
      position: absolute;
      background-color: var(--tertiary-color);
      height: 2px;
      width: 70%;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: $mobile) {
        height: 70%;
        right: calc(var(--element-whitespace) * -0.5 - 1px);
        top: 50%;
        left: auto;
        bottom: auto;
        transform: translateY(-50%);
        width: 2px;
      }
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

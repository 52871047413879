@import "styles/breakpoints.scss";
.PricingForm {
  display: flex;
  flex-direction: column;

  & > .Button {
    margin-left: var(--body-padding);
    margin-right: var(--body-padding);
    margin-top: var(--element-whitespace);
  }

  .PricingTable {
    border-collapse: collapse;
    border-bottom: 2px solid var(--tertiary-color);

    input[type="number"]{
      max-width: calc(50 / 16 * 1rem);
    }

    @media (min-width: $hd) {
      padding: var(--element-whitespace);
      border-left: 1px solid var(--tertiary-color);
      border-right: 1px solid var(--tertiary-color);
      border-bottom: 1px solid var(--tertiary-color);
    }
    .InfoIcon {
      position: absolute;
      right: calc(-10 / 16 * 1rem);
      top: calc(-10 / 16 * 1rem);
      .fill-color {
        fill: var(--tertiary-color);
      }
    }

    thead {
      background-color: var(--secondary-color-dark);
      border-bottom: 1px solid var(--tertiary-color);

      @media (min-width: $hd) {
        border-top: 1px solid var(--tertiary-color);
      }

      th {
        color: #fff;
        position: relative;
        p {
          position: fixed;
          width: 100%;
          padding: var(--element-whitespace);
          left: 0;
          background-color: var(--tertiary-color-light);
          border: 1px solid var(--tertiary-color);
          display: none;

          @media (min-width: $hd) {
            width: calc(100% - var(--element-whitespace) * 2);
            left: var(--element-whitespace);
          }
        }
        &:hover,
        &:focus,
        &:active {
          p {
            display: block;
          }
        }
      }
    }
    tr {
      &.table-section-header {
        width: 100%;
        &:not(:first-child){
          margin-top: var(--element-whitespace);
        }
        td{
          flex: 1;
          text-decoration: underline;
        }
      }
      &:not(.table-section-header){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      th,
      td {
        vertical-align: top !important;
        text-align: center !important;
        padding: calc(5 / 16 * 1rem) calc(10 / 16 * 1rem);
      }
    }
  }
}

.terms {
  margin: var(--element-whitespace) auto 0 auto;
  padding-left: var(--element-whitespace);
  padding-right: var(--element-whitespace);
  .CheckBox {
    margin: auto var(--element-whitespace) auto 0;
  }
  &>div{
    display: flex;
  }
  &>div:not(:last-child){
    margin-bottom: var(--element-whitespace);
  }

  .Link {
    text-decoration: underline;
    margin-right: var(--element-whitespace);
  }
}

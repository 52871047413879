.TermsOfSale{
    padding: var(--element-whitespace);
    position: fixed;
    z-index: 1;
    background-color: var(--tertiary-color-light);
    overflow-y: auto;
    .Link{
        text-decoration: underline;
    }
    section:not(:last-of-type){
        margin-bottom: var(--element-whitespace);
    }
    ul{
        list-style: none;
        padding-inline-start: 0;
    }
}
.Button {
  border: 0;
  border-radius: calc(8 / 16 * 1rem);
  cursor: pointer;
  padding: 0;
  background: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  //font-weight: bold;

  &:not([class*="-no-content"]){
    min-height: calc(40 / 16 * 1rem);
  }

  & > * {
    pointer-events: none;
  }

  span {
    text-align: center;
    z-index: 5;
    font-size: 1.1rem;
  }

  &[class*="-regular"] {
    padding: 0.5rem 1.5rem;
  }

  &[class*="-no-padding"] {
    display: flex;
    span {
      margin: auto;
      display: flex;
      a {
        display: flex;
        & > * {
          margin: auto;
        }
      }
      & > * {
        margin: auto;
      }
    }
  }

  &.button-transparent {
    background: none;
  }

  .button-background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 200%;
    height: 200%;
    transform: translate(0, 0);
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    .button-background {
      // 50% caused clipping sometimes
      transform: translate(49%, -49%);
    }
  }

  &.main-color {
    span {
      color: #fff;
    }
    .button-background {
      background: linear-gradient(
        45deg,
        var(--main-color),
        var(--main-color-light)
      );
    }
  }

  &.secondary-color {
    span {
      color: #fff;
    }

    .button-background {
      background: linear-gradient(
        45deg,
        var(--secondary-color),
        var(--secondary-color-light)
      );
    }
  }

  &.tertiary-color {
    .button-background {
      background: linear-gradient(
        45deg,
        var(--tertiary-color),
        var(--tertiary-color-light)
      );
    }
  }
}

@import "styles/breakpoints.scss";
.ChannelDropDown {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  &:not(:last-child){
    border-bottom: 2px solid var(--tertiary-color-light);
  }

  .dropdown-toggle {
    border-radius: 0;
    z-index: 1;
    h1 {
      text-align: left;
    }
    span {
      display: flex;
      flex-direction: row;
      &::after {
        margin: auto 0 auto auto;
        content: " \25BC";
      }
    }
  }

  .videos-container {
    transform: translateY(-102%);
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    transition: transform 0.2s ease-in-out;
    
    @media(min-width: $hd){
      display: grid;
      gap: var(--element-whitespace);
      grid-template-columns: 1fr 1fr 1fr;
    }

  }

  .Link {
    display: block;
  }

  .Link:not(:last-child) {
    @media (max-width: $hd) {
      border-bottom: 2px solid var(--secondary-color-dark);
    }
  }

  .LoadingIndicator{
    display: none;
  }
  
  .LoadingIndicator, .videos-pagination{       
      grid-column: 1 / 4;
  }

  .videos-pagination{
    margin-top: var(--element-whitespace);
    display: none;
    flex-direction: row;
    background-color: var(--tertiary-color-light);
    padding: var(--element-whitespace);
    .Button:first-child{
      margin-left: auto;
    }
    .Button{
      margin: 0 calc(5 / 16 * 1rem);
    }
    .Button:last-child{
      margin-right: auto;
    }
  }

  &.active:focus-within {
    .videos-container {
      transform: translateY(0);
      visibility: visible !important;
      pointer-events: all;
      position: static;
    }
    .LoadingIndicator{
      display: flex;
    }
    .videos-pagination{
      display: flex;

    }
    .dropdown-toggle {
      span {
        &::after {
          content: " \25B2";
        }
      }
    }
  }
}

@import "styles/breakpoints.scss";
.VideoContainer {
  width: 100%;
  max-width: calc(1000 / 16 * 1rem);
  display: flex;
  flex-direction: column;

  &.chat{
    max-width: 100%;
    &.horizontal{
      display: grid;
      grid-template-areas: 
        "player chat"
        "info info";
      grid-template-columns: 1fr 25rem;
    }
    .player-container{
      grid-area: player;
    }
    .chat-embed{
      grid-area: chat;
      width: 100%;
      height: 100%;
    }
    .video-info{
      grid-area: info;
    }
    .col-left{
      flex: 2;
      display: flex;
      flex-direction: column;
    }
    .col-right{
      flex: 1;
    }
  }
  
  .Form {
    max-width: calc(320 / 16 * 1rem);
    width: 100%;
    margin: auto;
    transform: translateZ(10px);
    background-color: var(--tertiary-color-light);
    padding: var(--element-whitespace) var(--body-padding) 0 var(--body-padding);
    border-radius: calc(10 / 16 * 1rem);
    border: 2px solid var(--tertiary-color);
    box-shadow: 0 0 calc(10 / 16 * 1rem) var(--tertiary-color);
    .Button {
      margin: var(--element-whitespace) auto;
      .LoadingIndicator {
        min-height: calc(40 / 16 * 1rem);
      }
    }
  }
  .player-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: var(--secondary-color-dark);
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .video-player{
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      filter: blur(3px) brightness(50%);
    }
    .form-container{
      display: flex;
      flex: 1;
    }
  }
  .video-player {
    width: 100%;
    height: 100%;
    transform: translateZ(10px);
  }
  
  .video-info {
    margin-bottom: var(--element-whitespace);
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    .video-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      padding: var(--element-whitespace);
      margin-bottom: var(--element-whitespace);
      background-color: var(--tertiary-color-dark);
      margin-top: -1px;
      @media(max-width: $hd){
        flex-wrap: wrap;
        .title-container{
          min-width: 50vw;
          margin-left: auto !important;
        }
        .Button{
          margin-left: auto !important;
        }
        .Image{
          padding-bottom: var(--element-whitespace) !important;
        }
      }
      .Image{
        width: 4.5rem;
        margin: auto auto auto 0;
      }
      .title-container {
        padding-left: var(--element-whitespace);
        margin: auto 0;
        flex: 1;
        * {
          color: #fff;
        }
      }
      .Button{
        margin: auto 0;
      }
      h1 {
        margin-bottom: 0;
        margin-right: auto;
        font-size: 1.5rem;
      }
      p {
        width: 100%;
      }
    }
    .video-description {
      display: flex;
      flex-direction: column;
      p,
      h2 {
        margin-bottom: calc(8 / 16 * 1rem);
        margin-left: var(--element-whitespace);
      }
      h2 {
        font-size: 1.1rem;
        margin-top: calc(5 / 16 * 1rem);
      }
      .Link{
        margin: var(--element-whitespace) auto;
        text-decoration: underline;
      }
    }
  }
  .video-info.vertical {
    padding-bottom: var(--element-whitespace);
    .video-title,
    .video-description {
      padding-left: var(--element-whitespace);
      padding-right: var(--element-whitespace);
    }
  }
  .CartContainer {
    min-width: calc(320 / 16 * 1rem);
    width: 100%;
    height: 100%;

    @media (min-width: $hd) {
      width: 90vw;
    }
    & > h1 {
      text-align: center;
    }
    .Form {
      border-radius: 0;
      padding: 0;
      max-width: 100%;
      border: none;
      box-shadow: none;
      background-color: #fff;

      .Ticket {
        .Button {
          margin: auto 0 auto var(--element-whitespace);
        }
      }

      .payment-provider {
        margin: calc(10 / 16 * 1rem);
      }
    }
  }
}

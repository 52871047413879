.PaymentsPage {
  padding-top: var(--element-whitespace);
  .payment-terms {
    text-align: center;
    margin: auto auto var(--element-whitespace) auto;
  }
  .Link {
    text-decoration: underline;
  }
  .payment-information, .payment-providers{
    border: 1px solid var(--secondary-color-dark);
    
    & > h3 {
      color: #fff;
      padding: var(--element-whitespace);
      text-align: center;
      background-color: var(--secondary-color-dark);
      max-width: 100%;
    }
  }
  .payment-information{  
    margin-bottom: var(--element-whitespace);
    &>*{
      padding: var(--element-whitespace);
    }
    h2{
      border-top: 1px solid var(--secondary-color-dark);
      text-align: right;
    }
    p{
      text-align: right;
      max-width: 100%;
      margin-bottom: 0;
      padding-top: 0;
    }
  }
  .payment-providers {
    border-radius: 0 !important;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    & > div {
      display: flex;
      flex-wrap: wrap;
      padding: var(--element-whitespace);
      width: 100%;
      h4 {
        width: 100%;
        margin: 0 0 0 var(--element-whitespace);
        max-width: 100%;
      }
    }
    .payment-provider {
      margin: calc(10 / 16 * 1rem);
      padding: calc(10 / 16 * 1rem);
      height: calc(60 / 16 * 1rem);
      min-width: calc(120 / 16 * 1rem);
      max-width: calc(21% + calc(20 / 16 * 1rem));
      flex: 1 0 21%;
      img {
        height: calc(40 / 16 * 1rem);
      }
    }

    .button-container {
      flex-direction: column;
      flex-wrap: nowrap;
      margin: auto;
      width: auto;
      .Button{
        width: 100%;
      }
      .Button:not(:last-child) {
        margin-bottom: var(--element-whitespace);
      }
    }
  }
}

@import "styles/breakpoints.scss";
.VideoCard {
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  transition: background-color 0.1s ease-in-out;
  background-color: var(--tertiary-color-light);
  cursor: pointer;

  @media(min-width: $hd){
    flex-direction: column;
  }

  &:hover{
    background-color: var(--tertiary-color);
  }

  .image-container {
    height: 20vw;
    width: calc(20vw * 16 / 9);
    background-color: var(--secondary-color-dark);
    display: flex;
    position: relative;
    overflow: hidden;
    @media (min-width: $hd) {
      height: calc(200 / 16 * 1rem);
      width: 100%;
    }
    img {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .info-container {
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: calc(3 / 16 * 1rem) 0;
    * {
      text-align: right;
      margin-right: var(--element-whitespace);
      margin-bottom: 0;
    }
    .time{
      margin-top: auto;
    }
    @media (max-width: $mobile) {
      h2 {
        font-size: 1.1em;
      }
    }
    @media (min-width: $hd) {
      padding: var(--element-whitespace);
      * {
        text-align: left;
        margin: 0;
      }
    }
  }
}

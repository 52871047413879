@import "styles/breakpoints.scss";
.ChannelsPage {
  .ChannelCard {
    background-color: var(--tertiary-color-dark);
    flex-direction: row;
    position: relative;
    * {
      color: #fff;
    }
    .channel-info{
        z-index: 1;
        position: relative;
        padding: var(--body-padding);
        &::before{
            z-index: -1;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0.8;
            background-color: var(--tertiary-color-dark);
        }
        &>*:not(h1):not(:last-child){
            margin-bottom: 0.5rem;
        }
        @media(min-width: $hd){
            padding-right: calc(calc(200 / 16 * 1rem) + var(--element-whitespace));
        }
    }
    .image-container {
      max-height: calc(200 / 16 * 1rem);
      height: 100%;
      display: flex;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      padding: var(--element-whitespace);
      .Image {
        height: 100%;
        margin-left: auto;
      }
    }
  }
}

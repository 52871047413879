@import "styles/breakpoints.scss";

.FrontPage {
  transform-style: preserve-3d;

  .MOTDS {
    min-height: calc(300 / 16 * 1rem);
    width: 100%;
    position: sticky;
    //pointer-events: none;
    overflow: hidden;
    background-color: var(--tertiary-color-light);
    z-index: -1;
    display: flex;
    flex-direction: column;

    .LoadingIndicator{
      margin: auto;
    }

    .Message {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      min-height: calc(300 / 16 * 1rem);
      display: flex;
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transform-style: preserve-3d;
      position: absolute;
      z-index: -2;
      opacity: 0;
      transition: opacity 1s;
      padding: var(--body-padding);
      transform: translateZ(-10px);
      .Button{
        pointer-events: all;
      }
      &.current-message{
        opacity: 1;
        position: relative;
        z-index: 2;
        transform: translateZ(0px);
      }

      .Image{
        object-fit: contain;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateZ(-10px);
        position: absolute;
        z-index: -1;
      }

      .text-content {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 calc(8 / 16 * 1rem) calc(32 / 16 * 1rem) 0 rgba(31, 38, 135, 0.37);
        border-radius: calc(5 / 16 * 1rem);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        color: var(--secondary-color-dark);
        padding: var(--element-whitespace) calc(var(--element-whitespace) * 2);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: auto;
        z-index: 1;

        & > *:not(button) {
          text-shadow: 0px 0px 1px #000;
        }

        @media (min-width: $hd) {
          text-align: right;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: 0;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0 calc(8 / 16 * 1rem) calc(32 / 16 * 1rem) 0 rgba(31, 38, 135, 0.37);
          backdrop-filter: blur(6.5px);
          -webkit-backdrop-filter: blur(6.5px);
          border-radius: calc(10 / 16 * 1rem);
          border: 1px solid rgba(255, 255, 255, 0.18);
        }

        & > * {
          margin: 0;
        }
      }

      .Button {
        margin-top: var(--element-whitespace);
        margin-left: auto;
      }
    }

    .carousel-indicators {
      z-index: 5;
      position: absolute;
      display: flex;
      bottom: calc(5 / 16 * 1rem);
      left: 50%;
      transform: translateX(-50%) translateZ(10px);

      .Button {
        width: calc(12 / 16 * 1rem);
        height: calc(12 / 16 * 1rem);
        border-radius: 50%;
        margin: 0 calc(5 / 16 * 1rem);
      }
    }
  }

  .content {
    flex: 1;
    background-color: #fff;
    padding: var(--body-padding);
  }
}

.AccessibilityStatementPage{
    padding-bottom: var(--body-padding);
    section{
        padding: var(--element-whitespace);
    }
    section:not(:last-of-type){
        margin-bottom: var(--element-whitespace);
    }
    .Link, a{
        text-decoration: underline;
        color: var(--secondary-color-light);
    }
    .PageDescription{
        margin-bottom: var(--element-whitespace);
    }
    .container{
        padding: 0 var(--element-whitespace);
    }
}
@import "styles/breakpoints.scss";
.SearchPage {
  padding-top: var(--element-whitespace);
  padding-bottom: var(--element-whitespace);
  .content {
    .VideoCard {
      flex-direction: row !important;

      @media (min-width: $hd) {
        .image-container {
          height: calc(150 / 16 * 1rem);
          width: calc(calc(150 / 16 * 1rem) * 16/9);
        }
      }
    }
    & > h2 {
      text-align: center;
      padding: 0 var(--element-whitespace) var(--element-whitespace) var(--element-whitespace);
    }
    .Link:not(:last-of-type) > .VideoCard {
      border-bottom: 2px solid var(--secondary-color-dark);
    }
    .page-switching-buttons {
      display: flex;
      .Button {
        margin-top: var(--element-whitespace);
        &:not(:last-child) {
          margin-right: calc(5 / 16 * 1rem);
        }
        &:first-child {
          margin-left: auto;
        }
        &:last-child {
          margin-right: auto;
        }
      }
    }
  }

  @media (min-width: 700px) {
    padding: var(--body-padding);
    .content {
      & > * {
        flex: 1;
        margin: auto;
      }

      .Link {
        flex: 1;
        min-width: calc(320 / 16 * 1rem);
      }
    }
  }
}

@import "styles/breakpoints.scss";
.Modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 10vh 0;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade_in 0.3s ease-in-out forwards;
  .modal-container {
    margin: auto;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--element-whitespace);
    animation: slide_in 0.3s ease-in-out forwards;
    background-color: #FFF;
    border: 2px solid var(--main-color);
    overflow-y: auto;
    @media (min-width: $hd){
      padding: var(--element-whitespace);
      width: auto;
    }

    &>.Button{
      margin-left: var(--body-padding);
      margin-right: var(--body-padding);
      margin-top: var(--element-whitespace);
    }

    .Cart{
      .submit-regular{
        margin-left: var(--body-padding);
        margin-right: var(--body-padding);
        margin-bottom: 0;
      }
    }
  }
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide_in {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(0%);
  }
}

.Link {
  text-decoration: none;

  &:hover {
    filter: invert(0.1);
  }

  &.link-light {
    color: #fff;
  }
  &.link-dark{
    color: var(--secondary-color-light);
  }
}

@import "./colors.scss";
@import "./dimensions.scss";
@import "./breakpoints.scss";

@function calc_relative_size($px) {
  @return $px / 16 * 1rem;
}

@mixin font($font-family, $font-weight, $font-file) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    src: url($font-file);
  }
}

@include font("Karla", 400, "../assets/fonts/Karla-Regular.ttf");
@include font("Karla-Medium", 500, "../assets/fonts/Karla-Medium.ttf");
@include font("Poppins", 400, "../assets/fonts/Poppins-Regular.ttf");

body {
  margin: 0;
}

:root {
  font-size: 16px;
  @media (min-width: $uhd) {
    font-size: calc(100vw / 1920 * 16);
  }
}

* {
  box-sizing: border-box;
  line-height: 1.5em;
  letter-spacing: 0.05em;
  word-spacing: 0.07em;
  word-break: break-word;
  color: var(--secondary-color-dark);
}

*:focus {
  //outline: none;
}

.stop-scroll {
  overflow: hidden;
}

// Text portions should not exceed width of 75 characters for readability
h1,
h2,
h3,
h4,
p {
  max-width: 75ch;
  margin: 0;
  &:not(:last-child) {
    margin-bottom: var(--element-whitespace);
  }
}

p,
span,
ul,
a,
input,
td,
select,
option,
button:not(.Button) {
  font-family: "Karla-Medium", "Arial", "Helvetica", sans-serif;
  font-size: 1em;
}

h1,
h2,
h3,
h4,
button,
th,
label,
.title-1,
.title-2,
.title-3,
.title-4 {
  font-family: "Poppins", "Arial", "Helvetica", sans-serif;
}
.title-1 {
  font-weight: bold;
  font-size: 2rem !important;
}
.title-2 {
  font-weight: bold;
  font-size: 1.5rem !important;
}
.title-3 {
  font-weight: bold;
  font-size: 1.4rem !important;
}

.title-4 {
  font-weight: bold;
  font-size: 1.1rem !important;
}

.Form {
  display: flex;
  flex-direction: column;

  label {
    display: block;
    margin-left: var(--element-whitespace);
    margin-bottom: calc(3 / 16 * 1rem);
  }

//   & > *:not(.Button) {
//     min-width: 180px;
//     font-size: 1.2em;
//     border-radius: 12px;
//     padding: 5px 10px;
//     border-style: solid;
//     border-color: var(--secondary-color-dark);
//     border-width: 1px;
//     width: 100%;
//   }
  & > *:not(label):not(.Button) {
    min-width: calc(180 / 16 * 1rem);
    font-size: 1.2rem;
    border-radius: calc(12 / 16 * 1rem);
    padding: calc(5 / 16 * 1rem) calc(10 / 16 * 1rem);
    border-style: solid;
    border-color: var(--secondary-color-dark);
    border-width: 1px;
    width: 100%;
  }

  label:not(:first-of-type) {
    margin-top: var(--element-whitespace);
  }

  .Button {
      margin: var(--element-whitespace) 0 0 auto;
  }
}

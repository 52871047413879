@import "./breakpoints.scss";

:root{
    --element-whitespace: calc(12 / 16 * 1rem);
    --body-padding: 2rem;
    --header-element-height: calc(40 / 16 * 1rem);

    @media(min-width: $hd){
        --element-whitespace: 1rem;
    }

    @media(min-width: $full-hd){
        --body-padding: 3.5rem;
    }
}


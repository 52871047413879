.SuccessfulPaymentPage{
    .confirmation-container{
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: var(--element-whitespace);
        h1{
            text-align: center;
            margin: 0 auto;
        }
    }
}
.LoadingIndicator {
  min-height: calc(100 / 16 * 1rem);
  display: flex;
  flex-direction: row;
  span {
    width: calc(10 / 16 * 1rem);
    height: calc(10 / 16 * 1rem);
    border-radius: 50%;
    background-color: var(--main-color);
    display: block;
    margin: auto calc(3 / 16 * 1rem);
    &:first-child {
      margin-left: auto;
      animation: loading 1s ease-in-out infinite;
    }
    &:nth-child(2){
        animation: loading 1s ease-in-out 0.1s infinite;
    }
    &:last-child {
      margin-right: auto;
      animation: loading 1s ease-in-out 0.2s infinite;
    }
  }
}

@keyframes loading {
  0% {
    transform: translateY(0%);
    background-color: var(--main-color);
  }
  20% {
    transform: translateY(-120%);
    background-color: var(--main-color-dark);
  }
  40%{
    transform: translateY(0%);
    background-color: var(--main-color);
  }
  100% {
    transform: translateY(0%);
    background-color: var(--main-color);
  }
}

.Alert{
    position: fixed;
    top: 0;
    left: 50%;
    max-width: 90%;
    z-index: 100;
    background-color: var(--secondary-color-dark);
    padding: var(--body-padding);
    transform: translateY(-100%) translateX(-50%);
    transition: all 0.3s ease-in-out;
    border: 2px solid var(--secondary-color);
    box-shadow: 0 0 calc(10 / 16 * 1rem) var(--tertiary-color);
    border-radius: calc(10 / 16 * 1rem);
    visibility: hidden;
    text-align: center;

    &.display-alert{
        top: 50%;
        visibility: visible;
        transform: translateY(-50%) translateX(-50%);
    }

    *{
        color: #FFF;
    }
}
.FreeContentStamp{
    background-color: red;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.5rem 5rem;
    transform: rotate(-45deg) translate(30%,100%);

    span{
        color: #FFF;
        font-size: 1.2rem;
    }
}